
import Vue, { VueConstructor } from "vue";
import KCrudTable, {
  CrudTableHeader,
} from "@/modules/crudTable/components/KCrudTable.vue";
import { ExportType, PaginatedRequest } from "@/application/api/getPaginated";
import { mapGetters } from "vuex";
import RequiredClientDialog from "@/modules/client/components/RequiredClientDialog.vue";
import {
  clientReturnIndex,
  ReturnIndexItem,
} from "@/modules/returns/api/return";
import ExportButton from "@/components/ExportButton.vue";
import ReturnsOverviewFilter from "@/modules/returns/components/ReturnsOverviewFilter.vue";

interface ComponentData {
  headers: CrudTableHeader[];
  selected: Partial<ReturnIndexItem> & { clientId: number; id: number }[];
  exports: ExportType[];
  filterComponent: VueConstructor;
  returnIndexData: ReturnIndexItem[];
}

export default Vue.extend({
  name: "ReturnsTable",
  components: {
    ExportButton,
    RequiredClientDialog,
    KCrudTable,
  },
  data: (): ComponentData => ({
    headers: [
      { value: "receiptDate", sortable: true },
      { value: "productCode", sortable: true },
      { value: "productName", sortable: false },
      { value: "stockStatus", sortable: false },
      { value: "batchNumber", sortable: false },
      { value: "bestBeforeDate", sortable: true },
      { value: "receivedQuantity", sortable: false },
      { value: "unit", sortable: false },
      { value: "orderNumber", sortable: false },
      { value: "reasonOfReturn", sortable: false },
    ],
    selected: [],
    exports: [],
    filterComponent: ReturnsOverviewFilter,
    returnIndexData: [],
  }),
  computed: {
    ...mapGetters("authorisation", ["client", "isClient"]),
    ...mapGetters("crudTable", ["getRouteSetting"]),
  },
  watch: {
    client: {
      handler() {
        this.resetTable();
      },
      deep: true,
    },
  },
  methods: {
    async returnIndex(data: PaginatedRequest) {
      if (!this.client?.id) throw "Client id is not set";
      const response = await clientReturnIndex(data, this.client?.id);
      this.returnIndexData = response.data.data;
      this.exports = response.data.export ?? [];
      return response;
    },
    refreshTable(): void {
      (this.$refs.table as any).getData();
    },
    resetTable(): void {
      (this.$refs.table as any).handleReset();
    },
  },
});
